import EnterpriseForm from '@/components/EnterpriseForm';
import { seoConfiguration } from '@/utils/seo-config';
import { keys } from '@/utils/storage-keys';

const KargoEarlyAccess = () => {
  return (
    <EnterpriseForm
      content={content}
      formGUID="8f1d6764-6fbf-4e19-b8a6-353ca6809d69"
      submitSuccessMessage={content.submitSuccessMessage}
      eventCode="early_access"
      onFormReady={($form: unknown) => {
      }}
    />
  );
};

const content = {
  seo: seoConfiguration['kargo-early-access'],
  header: 'Sign up for Kargo Enterprise',
  subheader:
    'Ready to get started with Kargo and take it for a spin?',
  formHelper:
    'Fill out the form and we’ll reach out to you ASAP to help you. Please use your work email!',
  submitSuccessMessage: 'Thank you for connecting with us.'
};

export default KargoEarlyAccess;
